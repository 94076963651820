import * as firebase from 'firebase/app';
import { db } from "../util/firebase"
import { getAuth, signOut /* createUserWithEmailAndPassword */ } from "firebase/auth";
import { getStorage, ref, listAll, getDownloadURL} from "firebase/storage";
import { getDocs, query, collection, orderBy} from "firebase/firestore";

import Papa from 'papaparse';

const doubleRightIcon = 'pi pi-fw pi-angle-double-right';

const genSimulationURL = (path) => `/simulations/${path}`

export const roughSizeOfObject = (object) => {
  var objectList = [], stack = [ object ], bytes = 0;

  while ( stack.length ) {
      var value = stack.pop();

      if ( typeof value === 'boolean' ){ bytes += 4; }
      else if ( typeof value === 'string' ){ bytes += value.length * 2;}
      else if ( typeof value === 'number' ){ bytes += 8; }
      else if(typeof value === 'object' && objectList.indexOf( value ) === -1){
          objectList.push( value );
          for( var i in value ){
              stack.push( value[i] );
          }
      }
  }
  return bytes;
}
export function roughSizeOfObjectV2(object) {
    var objectList = [],
      stack = [ object ],
      bytes = 0,
      value,
      i;

    while (stack.length) {
      value = stack.pop();

      if (typeof value === 'boolean') {
        bytes += 4;
      } else if (typeof value === 'string') {
        bytes += value.length * 2;
      } else if (typeof value === 'number') {
        bytes += 8;
      } else if (typeof value === 'object'
          && objectList.indexOf(value) === -1) {
        objectList.push(value);

        for (i in value) {
          if (value.hasOwnProperty(i)) {
            stack.push(value[i]);
          }
        }
      }
    }
    return bytes;
}

export const getSimulationsFromFirestore = async() => {
    const simulations = [];
    const q = query(collection(db, "simulations"), orderBy("index"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      simulations.push({id: doc.id, ...doc.data()});
    });

    return simulations;
}

export const uploadSimulationFiles = async() => {

}

export const createStorageFolder = async() => {

}


export const getAllSimulationsList = async() => {
    const allFolders = await getMainFoldersFromStorage();
    console.log("RESPONSE: ", allFolders)
    
    const allSimulations = [];
    allFolders.prefixes.forEach(prefix => {
        if(prefix.fullPath !== "other-files"){ 
            allSimulations.push({to: `/simulations/${prefix.fullPath}`, title: prefix.fullPath, icon: doubleRightIcon}); 
        }
    })

    return allSimulations;
}

const getMainFoldersFromStorage = async() => {
    const storage = getStorage();
    const storageRef = ref(storage, '/');
    const allFolders = await listAll(storageRef);
    return allFolders;
}

export const parseCSV_Data = async(url) => {
    const data = Papa.parse(await fetchCSVFromUrl(url));
    //console.log("DATA TOOLTIP PARAMS -- getCSVData(): ", data.data);
    return data.data;
  }
  async function fetchCSVFromUrl(url) {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder('utf-8');
    const csv = await decoder.decode(result.value);
    //console.log('CSV fetchCSV(): ', csv);
    return csv;
  }

  export const downloadGlobalTooltipParams = async( otherFilesPrefix = "/other-files" ) => {
    //const storage = getStorage();
    //const pathReference = ref(storage, `/other-files/parameters-tooltips.csv`);
    
    //const url = await getDownloadURL(pathReference);
    const TOOLTIP_PARAMETERS_URL = "https://firebasestorage.googleapis.com/v0/b/moral-dimensions.appspot.com/o/other-files%2Fparameters-tooltips.csv?alt=media&token=996123f9-0c8b-4c7d-a903-76a068aaf8e1"
    const tooltipParams = await getCSVData(TOOLTIP_PARAMETERS_URL);
    return tooltipParams;
  }


  export const downloadSimulation = async( folderPrefix ) => {
    const storage = getStorage();
    const pathReference = ref(storage, `/${folderPrefix}`);

    const simulationFiles = await listAll(pathReference);
    const simulation = await loadSimulationFromStorageV2(simulationFiles, folderPrefix, storage);

    return simulation;
  }

  export const loadSimulationFromStorageV2 = async(simulationFiles = {items: []}, title, storage) => {
    let simulation = { title, steps: -1.5, icon: 'pi pi-fw pi-angle-double-right  ', png: null, imageUrl: "" };
    const promises = [Promise.resolve(""),Promise.resolve(""),Promise.resolve([]),Promise.resolve({})];

    for(const index in simulationFiles.items){
      const file = simulationFiles.items[index];
      const splitFileName = file.name?.split(".") || [], hasExtension = splitFileName?.length > 1;
      //console.log("NAME: ",file.name)

      if(hasExtension && splitFileName[1] === "png") {
        const pngRef = ref(storage, file.fullPath);
        simulation.png = file;
        promises[0] = new Promise((resolve, reject) => { getDownloadURL(pngRef).then((url) => { return resolve(url); }).catch(err => { return reject(err); }) }) ;
      }
      else if(hasExtension && splitFileName[1] === "gif") {
        const gifRef = ref(storage, file.fullPath);
        simulation.gif = file;
        promises[1] = new Promise((resolve, reject) => { getDownloadURL(gifRef).then((url) => { return resolve(url); }).catch(err => { return reject(err); }) }) ;
      }
      else if(hasExtension && splitFileName[1] === "csv") {
          const csvRef = ref(storage, file.fullPath);
        
          promises[2] = new Promise((resolve, reject) => { 
            getDownloadURL(csvRef).then((url) => { 
                //console.log("URL", url)
                return getCSVData(url);
            }).then((csvData) => { 
              //console.log("DATA", csvData)
              return resolve(csvData);
            })
            .catch(err => { return reject(err); }) 
          });

          //const steps = csvData[1][csvData[1]?.length - 1];//The second row of the array is te array of timesteps, the final entry is the total number of timesteps
          simulation.csvRef = file;
      }
      else if(hasExtension && splitFileName[1] === "json"){
          const jsonRef = ref(storage, file.fullPath);
          //const url = await getDownloadURL(jsonRef);
          //const parametersJSON = await fetchJSON(url);
          promises[3] = new Promise((resolve, reject) => { 
            getDownloadURL(jsonRef).then((url) => { 
                return fetchJSON(url);
            }).then((parametersJSON) => { 
              return resolve(parametersJSON["Simulation Parameters"] || {});
            })
            .catch(err => { return reject(err); }) 
          });

          //console.log("parametersJSON: ", parametersJSON)
          //simulation.parameters = parametersJSON?.["Simulation Parameters"] || {};
      }
    }
    const [imageUrl, gifUrl, csvData, parametersJSON] = await Promise.all(promises);
    //console.log("Promise.all() RESPONSE:: ", { imageUrl, gifUrl, csvData, parametersJSON })
    simulation.imageUrl = imageUrl; 
    simulation.gifUrl = gifUrl; 
    
    const steps = csvData[1][csvData[1]?.length - 1];//The second row of the array is te array of timesteps, the final entry is the total number of timesteps
    simulation = { ...simulation, csv: csvData, steps };

    simulation.parameters = parametersJSON;
    //console.log("!!!!! SIMULATION !!! ::::: ", simulation);

    simulation.to = genSimulationURL(simulation.title)
    return simulation;
  }

  export const fetchJSON = async(jsonURL) => {
    const response = await fetch(jsonURL);
    const json = await response.json()
    return json
  }

  export const getCSVData = async(csvUrl) => {
    const fetchedCSV = await fetchCSV(csvUrl);
    const data = Papa.parse(fetchedCSV);
    //console.log("DATA parsed getCSVData(): ", data);
    return data.data;
  }
  const fetchCSV = async(csvUrl) => {
    const response = await fetch(csvUrl);
    
    //Don't forget to handle possible network errors
    if (!response.ok) { 
      throw new Error("NetworkError");
    }
    return response.text();
  }

  export const DeepClone = (obj) => {
    if (obj === null || typeof (obj) !== 'object') return obj === null ? null : obj;
    let newObj = {}

    if (Array.isArray(obj))
        newObj = [...obj];
    else if (typeof obj === "object")
        newObj = { ...obj };

    for (let prop in obj) {
        if (prop === "file")
            newObj[prop] = obj[prop]
        else if (typeof obj[prop] === "object" || typeof obj[prop] === "function")
            newObj[prop] = DeepClone(obj[prop]);
    }

    return newObj;
};

export const logout = async() => {
  await signOut(getAuth())
  window.location.reload()
}

export const numberWithCommas = (x) => {
  const num = parseInt(x)
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convertColorsToHex = (colors) => {
  return colors.map(color => {
      if(color === "White") return "#FFF";
      else if(color === "Magenta") return "#FF00FF";
      else if(color === "Cyan") return "#00FFFF";
      else if(color === "Red") return "#FF0000";
      else if(color === "Green") return "#00FF00";
      else if(color === "Blue") return "#0000FF";
      else if(color === "Yellow") return "#FFFF00";
  })
}

//generic decorator-type functions
export const isClanCompareActive = (activeTabIndex = 0) => activeTabIndex === 5 || activeTabIndex == '5';
