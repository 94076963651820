import React, { useContext, useEffect, useState, useRef } from 'react';
import {Link, withRouter } from "react-router-dom"
import { SimulationContext } from '../App';
import { db } from "../util/firebase"
import { collection,query, doc, setDoc, getDocs, getDoc, deleteDoc, addDoc, Timestamp } from "firebase/firestore"; 
import { TabMenu } from "primereact/tabmenu";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from 'firebase/storage';

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';

import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
//import { BlockUI } from 'primereact/blockui';
import graphsImg from "../layout/GraphsForLandingPage.png"
import ReCAPTCHA from "react-google-recaptcha";
import { Card } from 'primereact/card';
import Chart from 'react-apexcharts'
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState, convertFromHTML, convertFromRaw, EditorState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Tooltip } from 'primereact/tooltip';
import { fetchJSON, getCSVData, getSimulationsFromFirestore, roughSizeOfObject, roughSizeOfObjectV2 } from '../util/helpers';
import { formatSimulationDataForGraphs, getGlobalLineOptions } from '../util/ApexGraphs';

const BLOGS = "blogs";


const AdminPage = (props) => {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', marginTop:20 }}>
                {chooseButton}
                {/* uploadButton */}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Simulation Files Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [blogs, setBlogs] = useState([]);
    const [blog, setBlog] = useState("");

    const [simulations, setSimulations] = useState([]);
    const [simulation, setSimulation] = useState("");

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [copyrightDate, setCopyrightDate] = useState(new Date());

    const [errors, setErrors] = useState(null);
    const [editor, setEditor] = useState("");

    const setTabs = () => {
        const enabledTabs = [
            { label: "Blog Post", value: "blog" }, 
            { label: "Simulation", value: "simulation" },
            { label: "Inbox", value: "inbox", disabled:true }
        ];
        return enabledTabs;
    }


    useEffect(() => {
        handleLoadBlogPosts();
        handleLoadSimulations();
    }, [])
    useEffect(() => {
        setErrors(null);
    }, [title, date, editor])

    useEffect(() => {
        if(blogs?.length > 0 && blog?.id){setupExistingBlogData(blog)}
    }, [blog])

    const handleLoadSimulations = async() => {
        const allSims = await getSimulationsFromFirestore();
        setSimulations(allSims);
    }

    const handleLoadBlogPosts = async() => {
        const {docID} = props.match.params;

        const q = query(collection(db, BLOGS/* , orderBy("index") */));
        const querySnapshot = await getDocs(q);
        const allBlogs = []

        querySnapshot.forEach((doc) => {
            const blogData = {id: doc.id, ...doc.data()};
            allBlogs.push(blogData);
            if(docID === doc.id){ setBlog(blogData); }
        });

        console.log("ALL BLOGS: ", allBlogs);
        setBlogs(allBlogs);
    }

    const setupExistingBlogData = async(blogData) => {
        const {docID} = props.match.params;
        //window.alert(docID)
        if(docID){
            if(blogData.rawEditor)
                setEditor(EditorState.createWithContent(convertFromRaw(JSON.parse(blogData.rawEditor))));
            else
                setEditor(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(blogData.fullBlogpostHTML))));
           setTitle(blogData?.title);
           setDate(blogData?.date);

        }
        else{
            clearInputs()
            window.alert("error")
        }
    }

    const clearInputs = (refreshBlogs = false) => {
        setTitle("")
        setBlog("")
        setDate("")
        setEditor("")
        if(refreshBlogs){

        }
    }
    //const onUpload = (e) => { }

    const onSubmitForm = (e) => {
        if(activeTabIndex === 0) submitBlog(e);
        else if(activeTabIndex === 1) submitSimulation(e);
    }

    const submitSimulation = async(e) => {
        let {docID} = props.match.params;
        if(!docID) docID = doc(collection(db, "simulations")).id;
        e.preventDefault();

       /*  
        console.log("FILES: ",fileUploadRef?.current?.getFiles()?.[0]?.type)
        console.log("FILES: ",fileUploadRef?.current?.getFiles()?.[1]?.type)
        console.log("FILES: ",fileUploadRef?.current?.getFiles()?.[2]?.type) 
        */

        const CSV = fileUploadRef?.current?.getFiles()?.find(file => file?.type === "text/csv");
        const JSONFile = fileUploadRef?.current?.getFiles()?.find(file => file?.type === "application/json");
        const GIF = fileUploadRef?.current?.getFiles()?.find(file => file?.type ===  "image/gif");

        const allFilesChosen = CSV && JSON && GIF;

        if(allFilesChosen){
            setLoading(true);

            const csvRef = ref(getStorage(), `${CSV.name.replace(/\.[^/.]+$/, "")}/${CSV.name}`);
            const jsonRef = ref(getStorage(), `${CSV.name.replace(/\.[^/.]+$/, "")}/${JSONFile.name}`);
            const gifRef = ref(getStorage(), `${CSV.name.replace(/\.[^/.]+$/, "")}/${GIF.name}`);

            try{
                const promises = [{ref: csvRef, file: CSV}, {ref: jsonRef, file: JSONFile}, {ref: gifRef, file: GIF}]?.map(fileRef => uploadBytes(fileRef.ref, fileRef.file)) ;
                      
                await Promise.all(promises);
               // const splitName = 
                const gifURL = await getDownloadURL(gifRef) || ""; 
                let simulationData = {
                    index: 0,
                    name: `${CSV.name.replace(/\.[^/.]+$/, "")}`,
                    title: `${CSV.name.replace(/\.[^/.]+$/, "")}`,
                    resultComment: "",
                    simulationComment: "",
                    gifURL
                };

                //////////Begin CSV & Parameter Formatting
                const jsonURL = await getDownloadURL(jsonRef) || ""; 
                console.log("json: ",jsonURL)
                const parametersJSON = await fetchJSON(jsonURL);
                console.log("DATA JSON: ",parametersJSON);

                const csvURL = await getDownloadURL(csvRef) || ""; 
                const csvData = await getCSVData(csvURL);
                console.log("DATA CSV: ",csvData);

                const { headerParams, timestepsArray, allEvents, totalClans, selectedLineCharts, allLineChartsDataObj } 
                = formatSimulationDataForGraphs(csvData, activeTabIndex);
                console.log("headerParams: ",headerParams);
                
                //simulationData.resultComment = headerParams?.resultComment || "";
                //simulationData.simulationComment = headerParams?.simulationComment || "";

                const loadedSimulationData = { ...headerParams, allEvents, timestepsArray, totalClans };
                //Chart line options for configuration of each Apex graph
                //loadedSimulationData.globalOptions = getGlobalLineOptions(timestepsArray, loadedSimulationData);

                //simulationData.loadedSimulationData = loadedSimulationData;
                console.log("loadedSimulationData: ", {loadedSimulationData, 
                    sizeOf: roughSizeOfObject(loadedSimulationData),
                    sizeOfV2: roughSizeOfObjectV2(loadedSimulationData),
                });
                simulationData = {...simulationData, ...loadedSimulationData, parameters: parametersJSON["Simulation Parameters"]};
                //////////////

                //Get index from total docs
                const allSimulationDocs = await getSimulationsFromFirestore();
                simulationData.index = allSimulationDocs?.length >= 0 ? allSimulationDocs.length + 1 : 0;
                //////////////

                docID = "sfsFn060UqfZ2FNKyuXo";//TESTING
                const docRef = doc(db, "simulations", docID);
                await setDoc(docRef, simulationData);
 
                console.log("Beginning subcollection upload")
                const overviewDocRef = doc(db, "simulations", docID, "graphs", "Overview");
                await setDoc(overviewDocRef, {overviewData: allLineChartsDataObj[0]}); 
                const redDocRef = doc(db, "simulations", docID, "graphs", "Red Clan");
                await setDoc(redDocRef, {overviewData: allLineChartsDataObj[1]}); 
                const greenDocRef = doc(db, "simulations", docID, "graphs", "Green Clan");
                await setDoc(greenDocRef, {overviewData: allLineChartsDataObj[2]}); 
                const blueClanDocRef = doc(db, "simulations", docID, "graphs", "Blue Clan");
                await setDoc(blueClanDocRef, {overviewData: allLineChartsDataObj[3]}); 
                const yellowClanDocRef = doc(db, "simulations", docID, "graphs", "Yellow Clan");
                await setDoc(yellowClanDocRef, {overviewData: allLineChartsDataObj[4]});
                const clanCompareDocRef = doc(db, "simulations", docID, "graphs", "Clan Compare");
                await setDoc(clanCompareDocRef, {overviewData: allLineChartsDataObj[5]}); 

                //window.alert("Simulation edited successfuly.");
                
            }catch(err){
                //window.alert("Error uploading simulation files...")
                console.log("Error uploading simulation files... ", err)
                setLoading(false);
            }
            //window.alert("Upload Successful");
            setLoading(false);
        }
        else{
  /*           const fieldErrors = {}
            if(!title) fieldErrors.title = "Fill in a valid title before submitting."
            if(!date) fieldErrors.date = "Fill in a valid date before submitting"
            if(!editor) fieldErrors.editor = "Fill out the body of the blog post before submitting"
            setErrors(fieldErrors); */
        }

    }

    const submitBlog = async(e) => {
        //console.log("CALENDAR::: ", Date.now(copyrightDate))
        const {docID} = props.match.params;
        e.preventDefault();

        const allFieldsFilled = !!title.trim() && !!date.trim() && !!editor;

        if(allFieldsFilled){
            setLoading(true);

            const collectionRef = collection(db, BLOGS);

            const data = {
                fullBlogpostHTML: "" + draftToHtml(convertToRaw(editor.getCurrentContent())),
                rawEditor: JSON.stringify(convertToRaw(editor.getCurrentContent())),
                title,
                date,
                timestamp: Date.now(date),
                copyrightTimestamp: Date.now(copyrightDate),
            }

            try{
                if(docID){
                    const docRef = doc(db, BLOGS, docID);
                    await setDoc(docRef, data);
                    window.alert("Blog edited successfuly.");
                }
                else{
                    await addDoc(collectionRef, data);
                    window.alert("New blog added successfully.");
                }

            }catch(error){
                window.alert("There was an error uploading the blog to Firestore!");
                setLoading(false);
            }

            //const docRef = doc(db, "blogs", `${reverseTimestamp}_${newUID}`)
            setLoading(false);
            clearInputs()
            handleLoadBlogPosts()
        }
        else{
            const fieldErrors = {}
            if(!title) fieldErrors.title = "Fill in a valid title before submitting."
            if(!date) fieldErrors.date = "Fill in a valid date before submitting"
            if(!editor) fieldErrors.editor = "Fill out the body of the blog post before submitting"
            setErrors(fieldErrors);
        }
    }
    
    const deleteBlog = async(e) => {
        e.preventDefault();
        if(!!blog?.id){
            if (window.confirm("Are you sure you want to delete this blog?")) {
                setLoading(true);

                try{
                    await deleteDoc(doc(db, BLOGS, blog.id));
                    setLoading(false);
                    window.alert("Blog deleted successfully!");
                    props.history.replace("/admin-page")

                }catch(error){
                    window.alert("Error deleting blog from Firestore");
                    setLoading(false);
                }
            }
        }
    }

    const FooterCopyright = () => <>
        <Divider />
        <h6>Copyright 2023 Moral Dimension LLC, all rights reserved.</h6>
    </>
    const toolbarOptions = {
        options: ['blockType', 'inline', 'list', 'link', 'history', 'colorPicker',],
        inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
        options: ['unordered', 'ordered'],
        },
        blockType: {
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'],
        },
        history: {
        inDropdown: false,
        options: ['undo', 'redo'],
        }
        }

        const sideBarBlogs = () =>
            <div className="layout-menuitem-text col-12 md:col-2 lg:col-2 pl-0 pr-4 mt-2"   >
                            <ul className="layout-menu justify-content-center">

                            <li key="new-blog" className="menu-separator " style={{marginBottom: 16}}>
{/*                                     <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw ${!props.match.params?.docID ? "pi-book" : "pi-bookmark"} ${!props.match.params?.docID ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
 */}                                    <Link // className={"tooltip-item "} data-pr-tooltip={"This will take you to the home/landing page"} data-pr-position="right"
                                        data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to={`/admin-page`}
                                        onClick={() => {clearInputs()}}
                                        tooltip=''> New Blog</Link>
                                </li>
                                <Divider />

                                {[...blogs].map((blogItem, index) => {
                                    return <li key={`blog-${index}`} className="menu-separator " style={{marginBottom: 26}}>
                                    <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw ${blogItem?.title === blog?.title ? "pi-book" : "pi-bookmark"} ${blogItem?.title === blog?.title ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
                                    <Link // className={"tooltip-item "} data-pr-tooltip={"This will take you to the home/landing page"} data-pr-position="right"
                                        data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to={`/admin-page/${blogItem.id}`}
                                        onClick={() => {setBlog(blogItem)}}
                                        tooltip=''> {blogItem.title}</Link>
                                </li>
                                })}

                            </ul>
                        </div>;
        const sideBarSimulations = () =>
            <div className="layout-menuitem-text col-12 md:col-2 lg:col-2 pl-0 pr-4 mt-2"   >
                            <ul className="layout-menu justify-content-center">

                            <li key="new-blog" className="menu-separator " style={{marginBottom: 16}}>
{/*                                         <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw ${!props.match.params?.docID ? "pi-book" : "pi-bookmark"} ${!props.match.params?.docID ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
*/}                                        <Link // className={"tooltip-item "} data-pr-tooltip={"This will take you to the home/landing page"} data-pr-position="right"
                                        data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to={`/admin-page`}
                                        onClick={() => {clearInputs()}}
                                        tooltip=''> New Simulation</Link>
                                </li>
                                <Divider />

                                {[...simulations].map((simulationItem, index) => {
                                    return <li key={`blog-${index}`} className="menu-separator " style={{marginBottom: 26}}>
                                    <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw ${simulationItem?.title === simulation?.title ? "pi-book" : "pi-bookmark"} ${simulationItem?.title === simulation?.title ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
                                    <Link // className={"tooltip-item "} data-pr-tooltip={"This will take you to the home/landing page"} data-pr-position="right"
                                        data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to={`/admin-page/${simulationItem.id}`}
                                        onClick={() => {setSimulation(simulationItem)}}
                                        tooltip=''> {simulationItem?.title || simulationItem?.name}</Link>
                                </li>
                                })}

                            </ul>
                        </div>;

//This simulation studies the survival value of behaviors traditionally regarded as reflecting moral values.
    return (
        <div className="grid" style={{ marginLeft: 10, marginRight:6, marginTop: 22, }}>

{activeTabIndex === 0 && sideBarBlogs()}
{activeTabIndex === 1 && sideBarSimulations()}

{/*                 <ProgressSpinner style={{width: '150px', height: '150px', display:"flex", position: "absolute",  zIndex: 999}} strokeWidth="5"  />
 */}

                <form onSubmit={onSubmitForm} className="col-12 md:col-10 lg:col-10 pl-0 mt-2">

                <TabMenu scrollable className="tabmenu-text mb-3" model={setTabs()} activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)} />  

{activeTabIndex === 0 && <>
                <div class="row align-right">
                    <h1 style={{paddingBottom: 20}}><strong>Blog Article Editor</strong></h1>

                    <label htmlFor="title" className="block text-900 text-xl font-medium mb-2">
                        Title
                    </label>
                    <InputText value={title} onChange={e => setTitle(e.target.value)} inputid="title" type="text" placeholder="Title" className="w-full  mb-5" />
                    {errors?.title && 
                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                            <Message severity="error" text={errors.title} />
                        </div>}

                    <label htmlFor="date" className="block text-900 text-xl font-medium mb-2">
                        Date
                    </label>
                    <Calendar className="w-full  mb-5" value={date} onChange={(e) => setDate(e.value)} showIcon/>

                    <label htmlFor="date" className="block text-900 text-xl font-medium mb-2">
                        Copyright Date
                    </label>
                    <Calendar className="w-full  mb-5" value={copyrightDate} onChange={(e) => setCopyrightDate(e.value)}
                        id="yearpicker" view="year" dateFormat="yy"  yearRange="1930:2030" showIcon/>

                    {errors?.date && 
                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                            <Message severity="error" text={errors.date} />
                        </div>}

                    <div >
                        <Editor
                            toolbarOptions={toolbarOptions}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            placeholder="Enter blog...."
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", padding: 8, marginBottom: 10 }}
                            onEditorStateChange={setEditor}
                            defaultEditorState={editor}
                            editorState={editor}
                        />
                    </div>
                </div>
                
                {errors?.editor && 
                    <div className="flex align-items-center justify-content-between mb-5 gap-5">
                        <Message severity="error" text={errors.editor} />
                    </div>}

                    <div className="flex align-items-center justify-content-between mb-5 gap-5">
                        {loading && <ProgressSpinner style={{width: '75px', height: '75px',}} strokeWidth="5"  />}
                        <Button type='submit' label={!!blog ? "Save Changes" : "Submit Blog"} className="w-full p-3 text-xl" disabled={loading}></Button>
                        {!!blog && <Button type='' onClick={deleteBlog} label={"Delete Blog"} className="w-full p-3 text-xl" disabled={loading}></Button>}
                    </div>
</>}
{activeTabIndex === 1 && <>
    <Toast ref={toast}></Toast>
    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
    {/* <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" /> */}
    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

<FileUpload ref={fileUploadRef} name="demo[]" url="/api/upload" multiple accept=".csv,.text/csv,.json,.JSON,application/JSON,.json,application/json,image/*" maxFileSize={1000000}
    /* onUpload={onTemplateUpload} */ onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

{loading && <ProgressSpinner style={{width: '75px', height: '75px',}} strokeWidth="5"  />}
<Button type='submit' label={"Upload Simulation"} className="w-full p-3 text-xl mt-4" disabled={loading}></Button>
    
</>}

                </form>
                <FooterCopyright/>

        </div>);
};
export default withRouter(AdminPage);