import React, {useContext, createContext, useEffect, useState} from 'react';
import {Route, Switch, BrowserRouter as Router, Redirect, withRouter } from "react-router-dom"
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import "./App.scss"
import { ProgressSpinner } from 'primereact/progressspinner';
import { collection, doc, setDoc, Timestamp, query, getDocs, orderBy, where } from "firebase/firestore"; 
import LoginPage from "./login/LoginPage"
import MainLayout from './pages/MainLayout';
import DashboardMain from "./pages/DashboardMain"

import { getAuth } from "firebase/auth";
import { generateAllGraphsLineOptions, } from './util/ApexGraphs';
import { downloadGlobalTooltipParams, getAllSimulationsList, isClanCompareActive } from './util/helpers';
import LandingPageSimple from './pages/LandingPageSimple';
import { db } from "./util/firebase"
import { Dialog } from 'primereact/dialog';
import SimulationCompare from './pages/SimulationCompare';
import AdminPage from './pages/AdminPage';


export const AboutPage = () => <div><h4 className='mt-2'> About page! [User authenticated successfully] </h4></div>;

const layoutContextDefault = {        
    layoutConfig: {ripple: false, inputStyle: 'outlined', menuMode: 'static', colorScheme: 'light',  theme: 'vela-blue',  scale: 14},
    setLayoutConfig: () => {},
    layoutState: {staticMenuDesktopInactive: false,overlayMenuActive: false,profileSidebarVisible: false,configSidebarVisible: false,staticMenuMobileActive: false,menuHoverActive: false},
    setLayoutState: () => {},
    onMenuToggle: () => {},
    showProfileSidebar: () => {},
}
export const SimulationContext = createContext({allSimulations: [], setAllSimulations: () => {}, simulation: null,  setSimulation: () => {}, loading: false, setLoading: () => {}, clanCompareActive: false,
    selectedLineCharts: [], setSelectedLineCharts: () => {}, allLineChartsDataObj: {}, setAllLineChartsDataObj: () => {}, activeTabIndex: 0, setActiveTabIndex: () => {},
    displaySizeGraph: "small", setDisplaySizeGraph: () => {}, isOpen: true, setIsOpen: () => {}, layoutContext: layoutContextDefault, lineOptions: null, setLineOptions: () => {},
    clanCompareDisplayType: "overlayed", setClanCompareDisplayType: () => {}, pageYOffset: 0, setPageYOffset: ()=>{}, 
    showModal: false, setShowModal: () => {},  sim1: false, setSim1: () => {}, sim2: false, setSim2: () => {}});
export const LayoutContext = React.createContext(layoutContextDefault);

const App = (props) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(true); //Switch these back for login functionality
  const [doneAuthenticating, setDoneAuthenticating] = useState(true); //Switch these back for login functionality

  const [allSimulations, setAllSimulations] = useState([]);
  const [allLoadedSims, setAllLoadedSims] = useState([]);

  const [simulation, setSimulation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tooltipParametersGlobal, setTooltipParametersGlobal] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [displaySizeGraph, setDisplaySizeGraph] = useState("medium");
  const [isOpen, setIsOpen] = useState(true);
  const [lineOptions, setLineOptions] = useState(null);
  const [clanCompareDisplayType, setClanCompareDisplayType] = useState("overlayed");

  const [showModal, setShowModal] = useState(false);
  const [sim1, setSim1] = useState(false);
  const [sim2, setSim2] = useState(false);

  /////////////////////////////////////
    const [layoutConfig, setLayoutConfig] = useState({ripple: false, inputStyle: 'outlined', menuMode: 'static', colorScheme: 'light',  theme: 'vela-blue',  scale: 14});
    const [layoutState, setLayoutState] = useState({staticMenuDesktopInactive: false,overlayMenuActive: false, profileSidebarVisible: false,configSidebarVisible: false,staticMenuMobileActive: false,menuHoverActive: false});

    const onMenuToggle = () => {
        if (isOverlay()) {
            setLayoutState((prevLayoutState) => ({ ...prevLayoutState, overlayMenuActive: !prevLayoutState.overlayMenuActive }));
        }

        if (isDesktop()) {
            setLayoutState((prevLayoutState) => ({ ...prevLayoutState, staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive }));
        } else {
            setLayoutState((prevLayoutState) => ({ ...prevLayoutState, staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive }));
           // setSimulation({ ...simulation, layoutContext: { ...simulation.layoutContext, layoutState: {...simulation.layoutContext?.layoutState, profileSidebarVisible: !simulation.layoutContext?.layoutState?.profileSidebarVisible} } });

        }
    };
    const showProfileSidebar = () => { setLayoutState((prevLayoutState) => ({ ...prevLayoutState, profileSidebarVisible: !prevLayoutState.profileSidebarVisible })); }
    const isOverlay = () => layoutConfig?.menuMode === 'overlay';
    const isDesktop = () => window.innerWidth > 991;

  ////////////////

  const [selectedLineCharts, setSelectedLineCharts] = useState([])
  const [allLineChartsDataObj, setAllLineChartsDataObj] = useState({})

  //!! IMPORTANT -- provider initialization for child components !!//
  const providerValue = { 
    setAllSimulations, allSimulations, simulation, setSimulation, loading, setLoading, tooltipParametersGlobal, setTooltipParametersGlobal,
    selectedLineCharts, setSelectedLineCharts, allLineChartsDataObj, setAllLineChartsDataObj, activeTabIndex, setActiveTabIndex,
    displaySizeGraph, setDisplaySizeGraph, isOpen, setIsOpen, lineOptions, setLineOptions, 
    layoutContext: { layoutConfig, setLayoutConfig, layoutState, setLayoutState, onMenuToggle, showProfileSidebar }, 
    clanCompareActive: isClanCompareActive(activeTabIndex), clanCompareDisplayType, setClanCompareDisplayType,
    showModal, setShowModal, sim1, setSim1, sim2, setSim2
  };

  useEffect(() => {//Authenticate user initially
    getUserLoginSession();
  }, []);
  useEffect(() => {//Once the user is authenticated, get the list of simulations to populate the side menu (&& doOnce)
    if(authenticatedUser && !allSimulations?.length){ 
      handleGrabSimulationsListed();
    }
  }, [authenticatedUser]);

  useEffect(() => {
    //console.log("  ==== ", );
  }, []);
  
  useEffect(() => {
    if(simulation?.csv && allSimulations?.length) { 
        const clanCompareActive = isClanCompareActive(activeTabIndex);
        const loadedSimulationData = { ...simulation, clanCompareActive, selectedLineCharts: simulation.allLineChartsDataObj?.[activeTabIndex] };
        //const newSelectedCharts = generateAllGraphsLineOptions(loadedSimulationData, loadedSimulationData.selectedLineCharts, displaySizeGraph, clanCompareDisplayType) || [];

        //console.log("SIMULATION activeTabIndex: ", { clanCompareActive, clanCompareDisplayType, selectedLineCharts: newSelectedCharts, activeTabIndex });
        setSimulation({ ...simulation, clanCompareActive,/*  selectedLineCharts: newSelectedCharts */ });
    }
  }, [activeTabIndex, displaySizeGraph, clanCompareDisplayType]);

  const handlePullSimulationsFirestore = async(list = []) => {
    const q = query(collection(db, "simulations"), orderBy("index"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const indexFound = list.findIndex(el => el.title === doc.data().name || el.title === doc.data().title)
      if(indexFound > -1){
        list[indexFound].resultComment = doc.data().resultComment;
        list[indexFound].simulationComment = doc.data().simulationComment
        list[indexFound].id = doc.id;
        list[indexFound] = { ...doc.data(), ...list[indexFound]}
      }
    });

    return list;
  }

  const getUserLoginSession = () => {
      /* getAuth().onAuthStateChanged(async (user) => {
          if(user){ setAuthenticatedUser(user); }
          
          setDoneAuthenticating(true);
      }); */
  }

  const handleGrabSimulationsListed = async() => {
    try{
      let list = await getAllSimulationsList();
      const tooltipParameters = await downloadGlobalTooltipParams();
      //console.log("handleGrabSimulationsListed -- tooltipParameters::: ", tooltipParameters);
      list = await handlePullSimulationsFirestore(list);
      //console.log("LIST: ",list);
      setTooltipParametersGlobal(tooltipParameters);
      setAllSimulations(list);
    }catch(error){
      //console.log("ERROR handleGrabSimulationsListed - ", error)
    }
  }


  if (!doneAuthenticating){//Display simple spinner before checking for authentication
    return <div style={{display:"flex", alignItems:"center", }}>
              <ProgressSpinner style={{width: '75px', height: '75px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
           </div>;
  }
/*   else if(!authenticatedUser){//Display the login page
    return (<Router>
              <Switch>
                <Route path="/login" component={LoginPage}/>
                <Redirect from="/" to="/login" /> 
              </Switch>
            </Router>)
  } */
  else if(authenticatedUser){//Authenticated, and open to the user
    return (<>
          <Router >
      
              <Switch>
                <Route exact path="/home" component={() => 
                <SimulationContext.Provider value={providerValue}>
                          <MainLayout >
                              <LandingPageSimple></LandingPageSimple>
                          </MainLayout>
                    </SimulationContext.Provider>}
                />

            {/* <Route exact path="/admin-page/" component={() => <AdminPage></AdminPage>}/>
            <Route exact path="/admin-page/:docID" component={() => <AdminPage></AdminPage>}/> */}

                <Route exact path="/simulations/:simulationID" component={() => 
                  <SimulationContext.Provider value={providerValue}>
                          <MainLayout >
                              <DashboardMain ></DashboardMain> 
                          </MainLayout>
                    </SimulationContext.Provider>}
                />

                <Route exact path="/simulation-compare" component={() => 
                  <SimulationContext.Provider value={providerValue}>
                          <MainLayout >
                              <SimulationCompare ></SimulationCompare> 
                          </MainLayout>
                    </SimulationContext.Provider>}
                />

                {/* <Redirect from="/login" to="/about" />  */}
                <Redirect from="/" to="/home" /> 
                {/* <Redirect from="/about" to="/home" />  */}
              </Switch>
            </Router>
            </>)
  }
}
export default withRouter(App);